.admin-container {
    margin: 4vh 0 0 8vh;
    width: 80vh;
}

.card-row {
    display: flex;
    flex-wrap: nowrap; 
    justify-content: space-between; 
    gap:10px;
}

/* Style individual cards */
.card {
    flex: 0 0 230px; 
    height: 170px; 
    background-color: #f0f0f0; 
    padding: 20px; 
    text-align: center; 
    margin: 0 25px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* height: 170px; */
} 

.graph-row{
    display: flex;
    flex-wrap: nowrap; 
    justify-content: space-between; 
    gap:10px;
    margin-left: 2vh;
    margin-top: 50px;
}

.piechart-card{
    width: 65vh;    /*50%*/
    height: 300px;
}
.doughnut-content{
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 260px;
}
.barchart-card{
    width: 76vh;  /*50%*/
    height: 300px;
}

.barchart-content{
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 260px;
}

