
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #000;
  box-shadow: 0 10 10px rgba(0, 0, 0, 0.3);
  padding: 0 35px 0 15px;
  
}

.logo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.menu {
  display: none;
}

.avatar a{
  align-items: center;
  color: #fff;
  width: 350px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;

}

@media (min-width: 1200px) {
  .menu {
    display: flex;
    align-items: center;
  }

  .avatar {
    display: flex;
  }
}

