* {
  margin: 0;
  padding: 0;
}

.sider-logo {
  margin: 3rem 1rem 1rem 2rem;
  bottom: 2%;
  position: fixed;
}


.logo-image {
  
  transition: transform 0.4s ease; 
}

.rotated {
  transform: rotate(90deg); 
  margin-left: 46px;
  margin-top: 50px;
}

.sider-logo img {
  height: 102px;
  width: 36px;
}

