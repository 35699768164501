body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: Imported;
  src: url("./assets/fonts/Proxima-Nova-Medium.ttf");
}

body{
  margin: 0;
  font-family: Imported;
  font-size: 5px;
  background: #f5f5f5;
}

.navbar{
  position: sticky !important;
}