.left-side-nav{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
  

}
.ant-menu-vertical .ant-menu-item-selected {
  background-color: white ;
}

.ant-menu-light .ant-menu-item-selected{
  background-color: #81bc01 ;
  color: white;
}
