* {
  margin: 0;
  padding: 0;
}

.user-profile-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 4vh;
}

.card-row {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 0 15px;
  max-height: 81vh;
  color: #000;
}

.info-column {
  flex: 1 0 15%;
}


.profile-img-container {

  height: 130px;
  /* margin-bottom: 10px; */
  position: relative;
  width: 130px;
}
.profile-img-text {
  border-radius: 50%;
  height: 90%;
  width: 90%;
  position: relative;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.user-name {
   white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.welcome-column {
  flex: 1 0 57%;
}
.welcome-text{
  font-size: 13px;
}

.help-column {
  flex: 1 0 18%;
}

.separator {
  height: 1px;
  background-color: #00000030;
  margin: 16px 0;
}

/* Carousel styles (left and right buttons) */

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: black;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: black;
}

/* Card- Grid of user Page  */
/* .card-title{
  display: flex;
  justify-content: space-between;
  gap: 5px;
} */
/* .card-container {
  display: flex;
  justify-content: center;
}

.card-container .ant-card {
  width: 100%;
  height: 100%;
}
.card-content {
  word-wrap: break-word;
}

.modal-style{
  font-style: initial;
  font-weight: 600;
} */

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 4px;
}