*{
  margin: 0;
  padding:0;
  
}
.infra-form-layout{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.form-header{
  margin:10px;
}

.input:-internal-autofill-selected{
  background: "#fff" !important;
}

.upload-button {
  display: inline-block;
  position: relative;
  overflow: hidden;
}



.ant-upload {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 3px 15px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  color: rgba(0,0,0,0.75);
  transition: all 0.3s;
}
.ant-upload span{
  display: inline-block;
  vertical-align: middle;
}

.ant-upload:hover{
  border-color: #40a9ff;
}

.ant-upload input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.ant-form-item {
  margin-bottom: 14px;
}
.custom-label {
  font-size: 16px;
}
.custom-extra {
  font-size: 11px;
}
.custom-rule {
  font-size: 11px; 
}
