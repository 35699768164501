.detailsTable {
    background-color: #fff;
    border: 1px solid #ccc;
    border-collapse: collapse;
    border-radius: 20px;
    width: 100%;
  }
  .tableValues {
    border: 1px solid #e7e7e7;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    text-align: center;
  }
  
  .detailsTable tr{
    font-size: small;
  }
  
  .cloudTitle{
    font-size: medium;
  }
  
  .ant-form-item {
    margin-bottom: 14px;
  }
  .custom-label {
    font-size: 16px;
  }
  .custom-extra {
    font-size: 11px;
  }
  .custom-rule {
    font-size: 11px; 
  }